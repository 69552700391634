import SiteWrapper from "../../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../../assets/images/pagerduty_dxable_og.png";

import MdRenderer from "../../components/mdrender";
import * as md from "../../components/mdrender.module.sass";
import TitleBlock from "../../components/title";

import "./developers@gen.scss";

const GeneratedProductDevelopers = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>Developers - PagerDuty正規代理店 DXable</title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="Use Caseの中から開発者がPagerDutyを活用する方法を掲載しています。
顧客エクスペリエンスの問題を先読みする。中断を最小限に抑えて、本来の作業に集中するなど。"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="Use Caseの中から開発者がPagerDutyを活用する方法を掲載しています。
顧客エクスペリエンスの問題を先読みする。中断を最小限に抑えて、本来の作業に集中するなど。"
        />

        <meta
          property="og:title"
          content="Developers - PagerDuty正規代理店 DXable"
        />

        <meta
          property="og:description"
          content="Use Caseの中から開発者がPagerDutyを活用する方法を掲載しています。
顧客エクスペリエンスの問題を先読みする。中断を最小限に抑えて、本来の作業に集中するなど。"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/product/developers/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/product/developers/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow="Products"
        textGreen="Developers"
        overview="Use Caseの中から開発者がPagerDutyを活用する方法を掲載しています。<br />
顧客エクスペリエンスの問題を先読みする。中断を最小限に抑えて、本来の作業に集中するなど。"
      />

      <div className="developersWrapper">
        <div className="developersContainer">
          <div className="center-2">
            <h2 className="center-title">
              コードを所有し、インシデント管理を自動化することで、
              <br />
              より多くの時間をイノベーティブな作業に費やせます。
            </h2>
            <p className="center-para">
              開発者はただプログラミンをしているだけではありません。本番環境のサービスを成功させる責任を担っています。PagerDutyは、開発者がプログラムのパフォーマンスにすぐに対処でき、本番環境のアプリをスムーズにリリースできるようにします。
            </p>
          </div>
          <div className="center-1">
            <h2 className="center-title">インシデント対応ライフサイクル</h2>
            <p className="center-para">
              ご使用の監視ツールからアラートを受け取り、類似したアラートをインシデントごとに分類することで、オンコールの開発者、NOC、DevOpsエンジニアに関連する情報だけをEメール、電話、SMS、プッツ通知など各担当者にあった方法で送信します。
            </p>
            <p className="center-para margin-top-customise">
              運用手順書のような関連情報を表示したり、他のチームメンバーを参集したりして素早くインシデントを評価・解決し、過去のインシデントを学習して今後の問題発生を防ぎます。
            </p>
            <p className="center-para margin-top-customise">
              インシデントが解決すると、PagerDutyでは、事後検証を合理化して今後の対応をよりスピードアップし、繰り返し起きる問題を見分けることで、結果的に、優れたプログラムを書くことにチームは専念出来る様になります。
            </p>
            <div className="max-930px-img">
              <img
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Product/Developers/marketecture_dev_650d1e9bc3.png?ik-sdk-version=javascript-1.4.3&updatedAt=1672994677669&tr=q-100,f-webp"
                alt="インシデント対応ライフサイクル"
              />
            </div>
            <p className="center-para">
              インシデント管理を自動化して、優れたサービスの構築により多くの時間を割けるようになります。
              <br />
              開発者がPagerDutyを活用する方法を常にチェックします。
            </p>
          </div>
          <div className="blog-box two-col-media-contents">
            <div className="text-part">
              <h4 className="common-title">
                顧客エクスペリエンスの問題を
                <br />
                先読みする
              </h4>
              <p className="common-desc">
                何か問題が発生した場合、いち早く自分で情報を把握することが重要です。ユーザーが影響を受け、解約やログオフし、最悪の場合、競合他社に切り替えてしまう前に、状況をコントロールしましょう。
              </p>
              <p className="common-desc">
                PagerDutyのOperations Command ConsoleとIntelligent
                Applicationでは、お客様のインフラストラクチャの全体的なビューを提供し、問題の根源と影響を受ける依存関係のあるサービスを容易に特定します。
              </p>
              <p className="common-desc">
                サービスの停止による影響を視覚化することができれば、依存関係をより深く理解できるため、顧客に影響を与える前に問題を解決し、また将来的には質の高い製品を提供することができます。
              </p>
            </div>
            <div className="media-contents-part">
              <img
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Product/Developers/customer_experiences2_8b113cc126.png?ik-sdk-version=javascript-1.4.3&updatedAt=1672994678202&tr=w-720,q-100,f-webp"
                alt="顧客エクスペリエンスの問題を先読みする"
              />
            </div>
          </div>
          <div className="blog-box two-col-media-contents">
            <div className="text-part">
              <h4 className="common-title">
                中断を最小限に抑えて、
                <br />
                本来の作業に集中
              </h4>
              <p className="common-desc">
                重要事項に時間を割き、トラブルシューティングや目先の問題対応に追われる時間を低減します。
              </p>
              <p className="common-desc">
                PagerDutyでは、自動的にイベントを共通のフォーマットに正規化します。開発者はルールを使用してイベントワークフローを大規模にカスタマイズできます。アラートのフィルタリングにより、システム全体で適切な情報を簡単に見つけることができます。
                ルールエンジン、アラート抑制、グループ化機能を活用すれば、すべての適切な情報を参照して優先度の高い問題に集中できます。
              </p>
              <p className="common-desc">
                ご自身がオンコールの場合には、優先度が高く顧客に影響を与える問題についてのみ通知を受け、後は眠りたいですよね。
              </p>
            </div>
            <div className="media-contents-part">
              <img
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Product/Developers/rules2x_30246f2e6a.png?ik-sdk-version=javascript-1.4.3&updatedAt=1672994678132&tr=w-720,q-100,f-webp"
                alt="中断を最小限に抑えて、本来の作業に集中"
              />
            </div>
          </div>
          <div className="blog-box two-col-media-contents">
            <div className="text-part">
              <h4 className="common-title">
                インシデント対応の簡素化と
                <br />
                自動化
              </h4>
              <p className="common-desc">
                インシデント対応を自動化することで、運用作業ではなく効果的な解決に集中できます。
              </p>
              <p className="common-desc">
                PagerDutyは、インシデント対応プロセスを一元化、簡素化、自動化することで、問題を迅速かつ効率的に解決するのに役立ちます。
                PagerDutyを利用することより開発者は、インシデントの背景や状況、運用手順書情報、および以前の修復の詳細を確認できるので、インシデントの解決がさらに早くなります。
              </p>
              <p className="common-desc">
                PagerDutyは、自動化を活用してアラートを統合し、柔軟なAPIで手作業を最小限に抑えます。
                PagerDutyで自動的に通知を行い、適切なレスポンダーにエスカレートできるため、オンコール対応での不安や疲労を低減できます。
              </p>
            </div>
            <div className="media-contents-part">
              <img
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Product/Developers/simplify2x_37c89b648e.png?ik-sdk-version=javascript-1.4.3&updatedAt=1672994678129&tr=w-720,q-100,f-webp"
                alt="インシデント対応の簡素化と自動化"
              />
            </div>
          </div>
          <div className="blog-box two-col-media-contents mb-[100px]">
            <div className="text-part">
              <h4 className="common-title">現在ご利用のスタックをそのまま</h4>
              <p className="common-desc">
                プロセスを変更する必要はありません。すでに使用しているツールで作業します。
              </p>
              <p className="common-desc">
                175以上のネイティブインテグレーションと、拡張可能なPagerDuty
                APIを備えており、お客様ご利用のすべてのツールからのデータを統合し、既存のITインフラストラクチャのインサイトを提供します。
                当社の拡張イベントAPI
                v2では、すべての受信イベントを共通のフィールドに自動的に正規化します
              </p>
              <p className="common-desc">
                双方向性の拡張機能を使用して、ご自分の使い慣れたツールを使用して各人に合った方法で応答が可能です。ツールの切り替えがなくなり、好みのChatOpsツールを使用して問題をインラインで修正したり、運用関連のタスクを自動化することができます。
              </p>
            </div>
            <div className="media-contents-part">
              <img
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Product/Developers/integrations_spider2x_90f53924e2.png?ik-sdk-version=javascript-1.4.3&updatedAt=1672994677665&tr=w-720,q-100,f-webp"
                alt="現在ご利用のスタックをそのまま"
              />
            </div>
          </div>
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedProductDevelopers;
